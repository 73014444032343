<template>
	<div class="patient page">
		<NavMenu>
			<div class="right-warp">
				<div class="right-content">
					<h2 class="content-title">
						<div class="title">
							<img src="../../assets/img/icon_1.png" alt="ico-title" />
							<span>{{$t('case.title')}}</span>
						</div>
						<div class="mark">
							<span class="sig">*</span>
							<span>{{$t('case.mark')}}</span>
						</div>
					</h2>
					<div class="control">
						<div class="control-left">
							<div class="input-search">
								<input type="text" v-model="params.keyword" @keyup.enter="goSearch" :placeholder="$t('case.placeholder')" />
							</div>
							<div class="input-search input-timer">
								<el-date-picker v-model="datatime" type="datetimerange" format="yyyy-MM-dd" range-separator="-"
								 :start-placeholder="$t('case.startPlaceholder')" :end-placeholder="$t('case.endPlaceholder')">
								</el-date-picker>
							</div>
							<div class="btn-search" @click="goSearch" >{{$t('case.search')}}</div>
						</div>
						<div class="control-right">
							<div class="btn-add" @click="goCreate">{{$t('case.create')}}</div>
						</div>
					</div>
					<el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" :header-cell-style="{
              color: '#252B3B',
              'font-weight': 'bold',
              background: '#F8F9FA',
            }"
					 @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55"> </el-table-column>
						<el-table-column prop="case_number" :label="$t('case.serial')" width="120"></el-table-column>
						<el-table-column prop="information" :label="$t('case.information')" width="120"></el-table-column>
						<el-table-column prop="patient_name" :label="$t('case.name')" width="120"></el-table-column>
						<el-table-column prop="patient_number" :label="$t('case.patient')" width="120"></el-table-column>
						<el-table-column prop="slide_text" :label="$t('case.personal')" width="100"></el-table-column>
						<el-table-column prop="doctor" :label="$t('case.doctor')" width="120"></el-table-column>

						<el-table-column prop="add_time_text" :label="$t('case.time')" width="150" >
						</el-table-column>
						<el-table-column :label="$t('case.operate')" >
							<template slot-scope="scope">
								<el-button size="mini" :class="{'btn-report': scope.row.make_before}"  @click="preopreative(scope.row)">{{$t('case.pre')}}</el-button>
								<el-button size="mini" :class="{'btn-report': scope.row.make_after}" @click="postoperation(scope.row)">{{$t('case.post')}}</el-button>
								<el-dropdown trigger="click" @command="handleCommand" style="margin-left: 10px">
									<el-button size="mini">
										{{$t('case.more')}}<i class="el-icon-arrow-down el-icon--right"></i>
									</el-button>
									<el-dropdown-menu slot="dropdown">
										<el-dropdown-item :command="['handleDetails',scope.$index, scope.row]">{{$t('case.detail')}}</el-dropdown-item>
										<el-dropdown-item :command="['goEdit', scope.$index, scope.row]">{{$t('case.edit')}}</el-dropdown-item>
										<el-dropdown-item :command="['handleCopy', scope.$index, scope.row]">{{$t('case.copy')}}</el-dropdown-item>
										<el-dropdown-item :command="['handleDelete', scope.$index, scope.row]">{{$t('case.delete')}}</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
								
							</template>
						</el-table-column>
					</el-table>
					<div class="list-foot">
						<div class="">
							<el-button class="btn" @click="toggleSelection()">{{$t('case.reverse')}}</el-button>
							<el-button class="btn" @click="delAll()">{{$t('case.delBatches')}}</el-button>
						</div>
						<el-pagination @current-change="nowpage" background layout="prev, pager, next" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</NavMenu>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				multipleSelection: [],
				total: 0,
				datatime: null, //时间区间
				params: {
					page: 1,
					limit: 10,
					keyword: "",
					start: "",
					end: "",
					patient_id: ""
				}
			};
		},
		mounted() {
			this.params.keyword = this.$route.query.keyword ? this.$route.query.keyword : '';
			this.params.patient_id = this.$route.query.patient_id;
			this.getCaseList();
		},
		methods: {
			handleCommand(command) {
				let func = "this." + command[0] + "(" + command[1] + "," + command[2].id +")";
				eval(func);
			},
			//术后报告
			postoperation(row){
				if(!row.make_after){
					let routeUrl = this.$router.resolve({
						path: "/post/upload",
						query: {id: row.id}
					});
					return window.open(routeUrl .href, '_blank');
				}
				this.$router.push({
					path: "/post/report",
					query: {id: row.id}
				});
			},
			//术前报告
			preopreative(row){
				if(!row.make_before){
					let routeUrl = this.$router.resolve({
						path: "/pre/upload",
						query: {id: row.id}
					});
					return window.open(routeUrl.href, '_blank');
				}
				this.$router.push({
					path: "/pre/report",
					query: {id: row.id}
				});
				
			},
			// 编辑
			goEdit(index, id) {
				this.$router.push({
					name: "case-create",
					query: {
						id
					}
				})
			},
			// 创建病例
			goCreate() {
				this.$router.push({
					path: "/case/create"
				});
			},
			// 当前页
			nowpage(e) {
				this.params.page = e;
				this.getCaseList();
			},
			// 查询按钮
			goSearch() {
				this.params.page = 1;
				this.getCaseList();
			},
			// 获取列表
			getCaseList() {
				this.params.start = this.datatime ? this.datatime[0] : '';
				this.params.end = this.datatime ? this.datatime[1] : '';
				this.$api.caseList(this.params).then(res => {
					this.tableData = res.data.data;
					this.total = res.data.count;
				})
				
			},
			toggleSelection() {
				this.tableData.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});

				
			},
			headerRowStyle(row) {
				console.log(row);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			//复制
			handleCopy(index, id){
				this.$api.copyCase(id).then(res => {
					if (res.code == 1){
						// this.tableData.splice(index, 1);
						if(this.params.page == 1){
							return this.getCaseList()
						}
						return this.$message.success(res.msg);
					}
					this.$message.error(res.msg);
				})
				
			},
			handleDetails(index, id) {
				this.$router.push({path:"/case/details",query:{id}})
			},
			handleDelete(index, id) {
				this.$confirm(this.$t('case.delWarn'), this.$t('common.warn'), {
					confirmButtonText: this.$t('common.confirm'),
					cancelButtonText: this.$t('common.cancel'),
					type: 'warning'
				}).then(() => {
					this.$api.delCase(id).then(res => {
						if (res.code == 1) {
							this.tableData.splice(index, 1);
							return this.$message.success(res.msg);
						}
						this.$message.error(res.msg);
					})
				})
			},
			delAll(){
				if(this.multipleSelection.length > 0){
					var c = [];
					this.multipleSelection.map(res=>{
						c.push(res.id);
					})
					this.$confirm(this.$t('case.delWarn'), this.$t('common.warn'), {
						confirmButtonText: this.$t('common.confirm'),
						cancelButtonText: this.$t('common.cancel'),
						type: 'warning'
					}).then(() => {
						this.$api.delCase(c.join(',')).then(res => {
							this.$message.success(res.msg);
							if (res.code == 1) {
								this.params.page = 1;
								this.getCaseList();
							}
						})
					})
				}
			},
		},
	};
</script>
<style scoped>
	.right-content {
		min-height: 757px;
		position: relative;
	}

	.content-title .mark {
		font-family: FZZhunYuan-M02S;
		font-weight: 400;
		line-height: 159px;
		font-size: 13px;
	}

	.sig {
		color: #ff0000;
	}

	.right-content .form {
		display: flex;
		justify-content: space-between;
		padding: 30px;
	}

	.right-content .form-left,
	.right-content .form-right {
		width: 45%;
	}

	.right-content .form .foot {
		position: absolute;
		bottom: 25px;
		left: 0;
		right: 0;
		text-align: center;
	}

	.right-content .form .foot input {
		margin: 0 12px;
	}

	.btn {
		background: #fff;
	}

	.control {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 27px;
		margin-top: 20px;
	}

	.control .control-left {
		display: flex;
		align-items: center;
	}

	.input-search {
		position: relative;
	}

	.el-date-editor {
		width: 250px;
		height: 35px;
		background: #f6f6f6;
		border: none;
		border-radius: 18px;
		padding-left: 25px;
		font-size: 15px;
		font-weight: 400;
	}

	.input-search input {
		width: 181px;
		height: 35px;
		background: #f6f6f6;
		border: none;
		border-radius: 18px;
		padding-left: 60px;
		font-size: 15px;
		font-weight: 400;
	}

	.input-search:before {
		content: "";
		background: url(../../assets/img/search_1.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 17px;
		height: 17px;
		position: absolute;
		top: 50%;
		left: 28px;
		transform: translate(0%, -50%);
	}

	.input-search:focus {
		outline: 0;
		border: 0;
	}

	.btn-search {
		width: 85px;
		height: 35px;
		line-height: 35px;
		background: #364284;
		border-radius: 18px;
		font-size: 15px;
		font-weight: 400;
		color: #ffffff;
		position: relative;
		text-align: center;
		padding-left: 40px;
		/* margin: 0 17px; */
	}

	.input-timer {
		margin: 0 17px;
	}

	.btn-add {
		width: 105px;
		height: 35px;
		line-height: 35px;
		background: #364284;
		border-radius: 18px;
		font-size: 15px;
		font-weight: 400;
		color: #ffffff;
		position: relative;
		text-align: center;
		padding-left: 20px;
		margin: 0 17px;
	}

	.btn-search:hover,
	.btn-reset:hover,
	.btn-add:hover {
		cursor: pointer;
	}

	.btn-search:before {
		content: "";
		background: url(../../assets/img/search_1.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 17px;
		height: 17px;
		position: absolute;
		top: 50%;
		left: 28px;
		transform: translate(0%, -50%);
	}

	.btn-add:before {
		content: "";
		background: url(../../assets/img/create.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		width: 16px;
		height: 15px;
		position: absolute;
		top: 50%;
		left: 20px;
		transform: translate(0%, -55%);
	}

	.btn-reset {
		width: 125px;
		height: 35px;
		background: #7ecdf6;
		border-radius: 18px;
		font-size: 15px;
		font-weight: 400;
		color: #ffffff;
		line-height: 35px;
		text-align: center;
	}

	.list-foot {
		padding: 20px 0;
		display: flex;
		justify-content: space-between;
		align-content: space-between;
		
	}
	.btn-report{
		background: #364284;
		color: #fff;
	}
</style>
